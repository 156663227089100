<template>
  <section class="mediaCarrousel">
    <VueSlickCarouselc  :lazyLoad="'progressive'" VueSlickCarouselcv-bind="settings" class="mediaCarrousel__wrapper" style="width: 100%; height: 100%" v-if="media.length">
      <template #prevArrow="">
        <div class="mediaCarrousel__customArrow">
          <iconic class="mediaCarrousel__iconArrow mediaCarrousel__iconArrow--prev" name="arrow_right" />
        </div>
      </template>
      <div class="mediaCarrousel__contentImg" v-for="(photo, idx) in media" @click.stop="showMediaInModal(idx)" :key="idx">
        <div class="mediaCarrousel__photoImg" :style="`background-image: url(${photo.link})`"></div>
      </div>
      <template #nextArrow="">
        <div class="mediaCarrousel__customArrow">
          <iconic class="mediaCarrousel__iconArrow mediaCarrousel__iconArrow--next" name="arrow_right" />
        </div>
      </template>
    </VueSlickCarouselc>
  </section>
</template>

<script>
export default {
  components: {
    VueSlickCarouselc: () => import("vue-slick-carousel"),
  },
  props: ["media", "recordId"],
  computed: {
    screenWidth() {
      return this.$store.state.screen.width;
    },
    settings() {
      const breakpoints = [
        { breakpoint: 370, slidesToShow: 1 },
        { breakpoint: 495, slidesToShow: 2 },
        { breakpoint: 620, slidesToShow: 3 },
        { breakpoint: 860, slidesToShow: 4 },
        { breakpoint: 880, slidesToShow: 2 },
        { breakpoint: 1160, slidesToShow: 3 },
        { breakpoint: 1450, slidesToShow: 4 },
        { breakpoint: 1500, slidesToShow: 2 },
        { breakpoint: 1600, slidesToShow: 3 },
      ];

      const responsive = breakpoints.map(({ breakpoint, slidesToShow }) => {
        return { breakpoint, settings: { arrows: true, slidesToShow } };
      });

      return {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchThreshold: 5,
        responsive,
      };
    },
  },
  methods: {
    showMediaInModal(initialSlide) {
      this.$store.state.records.imagesToShow = {
        images: this.media,
        initialSlide,
        recordId: this.recordId,
      };
    },
  },
};
</script>

<style lang="scss">
.mediaCarrousel {
  height: 100%;
  width: 100%;
  .slick-list,
  .slick-track {
    height: 100%;
    max-height: 100%;
  }
  &__customArrow {
    @include Flex(row);
    top: 15px;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }
  &__iconArrow {
    color: $chicago;
    font-size: 18px;
    &--prev {
      transform: rotateY(180deg);
    }
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
  }
  .slick-prev {
    left: -10px;
  }
  .slick-next {
    right: -10px;
  }
  .slick-disabled {
    display: none;
  }
  &__wrapper {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  &__contentImg {
    padding: 0 10px;
    height: 100%;
    width: 105px;
    max-height: 150px;
  }
  &__photoImg {
    height: 150px;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 0px 4.942477703094482px 0px #0000004d;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
  }
}
</style>
